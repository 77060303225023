import { ChangeDetectionStrategy, Component, inject, OnInit, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';

import { LayoutService } from '@services/layout/layout.service';

@Component({
  selector: 'app-layout-manager',
  standalone: true,
  imports: [ButtonModule, DropdownModule, FormsModule],
  templateUrl: './layout-manager.component.html',
  styleUrl: './layout-manager.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutManagerComponent implements OnInit {
  private layoutService = inject(LayoutService);

  closing = output<void>();

  layouts = signal<string[]>([]);

  editIndex: number | null = null;

  newString: string = '';

  defaultLayout: string = '';

  constructor() {
    this.layoutService.activeGroup$.subscribe((group) => {
      console.log('Active group changed in layout manager', group);
      this.layouts.set(group.layouts);
      this.defaultLayout = group.default;
    });
  }

  ngOnInit() {
    console.log('Layout manager initialized');
  }

  // Set default layout
  onLayoutSelect(layout: string) {
    this.defaultLayout = layout;
    this.layoutService.setDefaultLayout(layout);
    this.closing.emit();
  }

  addString() {
    if (this.newString.trim()) {
      this.layouts.set([...this.layouts(), this.newString.trim()]);
      this.newString = '';
    }
  }

  // Edit existing item
  startEdit(index: number) {
    this.editIndex = index;
    this.newString = this.layouts()[index];
  }

  editString() {
    if (this.editIndex !== null && this.newString.trim()) {
      const tmp = [...this.layouts()];
      tmp[this.editIndex] = this.newString.trim();
      this.layouts.set(tmp);
      this.cancelEdit();
    }
  }

  // Delete item
  deleteLayout(index: number) {
    this.layoutService.removeLayout(this.layouts()[index]);
  }

  // Cancel edit or add
  cancelEdit() {
    this.editIndex = null;
    this.newString = '';
  }

  cancelAdd() {
    this.newString = '';
  }
}
