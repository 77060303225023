import { inject, Injectable } from '@angular/core';
import { ILayoutGroup } from '@models/layout-group';
import { LayoutGroupName } from 'app/enums/layout-group-name.enum';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private defaultGroupList: ILayoutGroup[] = [
    {
      name: LayoutGroupName.GlobalTracking,
      layouts: ['Default'],
      default: 'Default'
    },
    {
      name: LayoutGroupName.FacilityTracking,
      layouts: ['Default'],
      default: 'Default'
    },
    {
      name: LayoutGroupName.DispensedDrugs,
      layouts: ['Default'],
      default: 'Default'
    },
    {
      name: LayoutGroupName.DestroyedDrugs,
      layouts: ['Default'],
      default: 'Default'
    }
  ];

  private groups: ILayoutGroup[] = [];

  /** Currently active group (initialized to GlobalTracking) */
  private activeGroupSubject = new BehaviorSubject<ILayoutGroup>(this.defaultGroupList[0]);

  /** Currently active group for "clients" to subscribe to */
  public activeGroup$: Observable<ILayoutGroup> = this.activeGroupSubject.asObservable();

  private messageService = inject(MessageService);

  constructor() {
    const savedGroups = this.loadFromLocalStorage();
    this.groups = savedGroups.length > 0 ? savedGroups : this.defaultGroupList;
    this.loadActiveGroupFromLocalStorage();
  }

  private loadFromLocalStorage(): ILayoutGroup[] {
    const data = localStorage.getItem('layoutGroups');
    if (!data) {
      return [];
    }

    const parsedGroups: ILayoutGroup[] = JSON.parse(data);
    return parsedGroups;
  }

  private saveToLocalStorage(): void {
    localStorage.setItem('layoutGroups', JSON.stringify(this.groups));
    localStorage.setItem('activeGroupName', this.activeGroupSubject.value.name);
  }

  private loadActiveGroupFromLocalStorage(): void {
    const activeGroupName = localStorage.getItem('activeGroupName');
    if (activeGroupName) {
      console.info(`in loadActiveGroupFromLocalStorage: activeGroupName already exists and is ${activeGroupName}`);
      this.setActiveGroup(activeGroupName);
    } else {
      console.info(`in loadActiveGroupFromLocalStorage: activeGroupName DOESN'T exist`);
      this.setActiveGroup(LayoutGroupName.GlobalTracking);
    }
  }

  getGroups(): ILayoutGroup[] {
    return this.groups;
  }

  addGroup(groupName: string): void {
    console.info(`in addGroup(${groupName})`);
    const newGroup: ILayoutGroup = { name: groupName, layouts: ['Default'], default: 'Default' };
    this.groups.push(newGroup);
    this.saveToLocalStorage();
  }

  removeGroup(groupName: string): void {
    console.info(`in removeGroup(${groupName})`);
    this.groups = this.groups.filter((group) => group.name !== groupName);
    if (this.activeGroupSubject.value.name === groupName) {
      this.setActiveGroup(LayoutGroupName.GlobalTracking);
    }
    this.saveToLocalStorage();
  }

  // Adds layout to active group and sets it as default
  addLayout(layout: string): void {
    console.info(`in addLayout(${layout})`);
    const activeGroup = this.activeGroupSubject.value;
    activeGroup.layouts.push(layout);
    activeGroup.default = layout;
    this.saveToLocalStorage();
  }

  //sets the default layout for the active group
  setDefaultLayout(layout: string): void {
    console.info(`in setDefaultLayout(${layout})`);
    const activeGroup = this.activeGroupSubject.value;
    activeGroup.default = layout;
    this.activeGroupSubject.next(activeGroup);
    this.saveToLocalStorage();
  }

  removeLayoutFromGroup(groupName: string, layoutName: string): void {
    console.info(`in removeLayoutFromGroup(${groupName}, ${layoutName})`);
    this.groups.forEach((group) => {
      if (group.name === groupName) {
        group.layouts = group.layouts.filter((layout) => layout !== layoutName);
      }
    });
    this.saveToLocalStorage();
  }

  removeLayout(layoutName: string): void {
    const activeGroup = this.activeGroupSubject.value;

    //if its the last layout, don't remove it, throw an error
    if (activeGroup.layouts.length === 1) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Cannot remove the last layout' });
      return;
    }

    activeGroup.layouts = activeGroup.layouts.filter((layout) => layout !== layoutName);

    if (activeGroup.default === layoutName) {
      activeGroup.default = activeGroup.layouts[0];
    }
    this.activeGroupSubject.next(activeGroup);
    this.saveToLocalStorage();
  }

  resetGroups(groupName: string): void {
    console.info(`in resetGroups(${groupName})`);
    this.groups = this.defaultGroupList;
    this.setActiveGroup(groupName);
  }

  getGroupByName(groupName: string): ILayoutGroup | undefined {
    console.info(`in getGroupByName(${groupName})`);
    return this.groups.find((group) => group.name === groupName);
  }

  // Set the active group by name
  setActiveGroup(groupName: string): void {
    console.info(`in setActiveGroup(${groupName})`);
    const group = this.getGroupByName(groupName);
    if (group) {
      this.activeGroupSubject.next(group);
      this.saveToLocalStorage();
    }
  }

  // Get the default layout from a group
  getDefaultLayout(groupName: string): string | undefined {
    console.info(`in getDefaultLayout(${groupName})`);
    const group = this.getGroupByName(groupName);
    return group?.default;
  }
}
