import { ValueFormatterParams } from '@ag-grid-community/core';

/**
 * Formats a Date object with NO seconds
 * @example "2012-06-01T05:00:00.000Z" => "06/01/2012, 12:00 AM"
 */
const noSecondsFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true
});

/**
 * Formats a Date object to be date only.
 * @example "2012-06-01T05:00:00.000Z" => "06/01/2012"
 */
export const dateOnlyFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
});

/**
 * Formats a date with the current local's (short) date format
 * @param params The value formatter params for the date column
 * @returns Locale formatted (short) date
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dateValueFormatter = (params: ValueFormatterParams<any, Date>) => {
  // Make sure this field actually HAS a value before we try to format it
  if (params.value !== null && params.value !== undefined) {
    return dateOnlyFormatter.format(params.value);
  }

  return '';
};

/**
 * Formats a date with the current local's date/time format
 * @param params The value formatter params for the date column
 * @returns Locale formatted date/time
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dateTimeValueFormatter = (params: ValueFormatterParams<any, Date>) => {
  // Make sure this field actually HAS a value before we try to format it
  if (params.value !== null && params.value !== undefined) {
    return params.value.toLocaleString();
  }

  return '';
};

/**
 * Formats a date with the current local's date/time format but with no seconds (ie: '12/19/2020, 09:23 PM')
 * @param params The value formatter params for the date column
 * @returns Locale formatted date/time
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dateTimeValueNoSecondsFormatter = (params: ValueFormatterParams<any, Date>) => {
  // Make sure this field actually HAS a value before we try to format it
  if (params.value !== null && params.value !== undefined) {
    return noSecondsFormatter.format(params.value);
  }

  return '';
};
