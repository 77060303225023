import { Injectable } from '@angular/core';
import { BlankTenant, TenantInfo } from 'app/constants/tenants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TenantHelperService {
  /** Internal behavior subject for tenant information  */
  private _tenant = new BehaviorSubject<TenantInfo>(BlankTenant);

  /** Observable to pipe tenant changes to where it is needed */
  tenant = this._tenant.asObservable();

  setTenant(tenant: TenantInfo) {
    this._tenant.next(tenant);
  }

  constructor() {}
}
