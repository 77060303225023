export interface ILayoutGroup {
  name: string;
  layouts: string[];
  default: string;
}

export class LayoutGroup {
  name: string = '';
  layouts: string[] = [];
  default: string = '';

  constructor(model?: ILayoutGroup) {
    this.name = model?.name ?? '';
    this.layouts = model?.layouts ?? [];
    this.default = model?.default ?? '';
  }
}
