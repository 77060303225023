export type Tenants = '' | 'HCA' | 'RegionalOneHealth';

export type TenantInfo = {
  tenant: Tenants;
  path: string;
  logoFileName: string;
};

export const BlankTenant: TenantInfo = {
  tenant: '',
  path: '',
  logoFileName: ''
};

export const tenantPaths: TenantInfo[] = [
  { tenant: 'HCA', path: '/hca', logoFileName: 'hca_logo.png' },
  {
    tenant: 'RegionalOneHealth',
    path: '/regionalonehealth',
    logoFileName: 'regionalonehealth_logo.png'
  }
];
