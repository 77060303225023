import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

/**
 * Used to help simplify generic HTTP calls
 */
@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {
  private http = inject(HttpClient);

  /**
   * The headers that need to be included with most/all API calls to tell the server that
   * we want JSON returned to us.
   */
  public jsonHeaders = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8'
  });

  /**
   * Performs a basic GET action to an URL without any parameters
   * @param url URL to call
   * @returns Observable with the result of the API call of the GET
   */
  public get<T>(url: string) {
    return this.http.get<T>(url, {
      withCredentials: true,
      responseType: 'json',
      headers: this.jsonHeaders
    });
  }

  /**
   * ***THIS DOES NOT WORK WITH ASP.NET FRAMEWORK WEB SERVICE CALLS*** Performs a basic GET action to an URL WITH any parameters
   * @param url URL to call
   * @param parameters Parameters to include in the URL
   * @returns Observable with the result of the API call of the GET
   */
  public getWithParams<T>(url: string, parameters: { [key: string]: string | number | boolean }) {
    return this.http.get<T>(url, {
      params: parameters,
      withCredentials: true,
      responseType: 'json',
      headers: this.jsonHeaders
    });
  }

  /**
   * Performs a basic POST to an URL with a body.
   * @param url URL to call
   * @param parameters Parameters to include as the body of the call
   * @returns Observable with the result of the API call of the POST
   */
  public post<T>(url: string, parameters: object) {
    return this.http.post<T>(url, JSON.stringify(parameters), {
      withCredentials: true,
      responseType: 'json',
      headers: this.jsonHeaders
    });
  }

  /**
   * Performs a basic POST to an URL WITHOUT a body.
   * @param url URL to call
   * @returns Observable with the result of the API call of the POST
   */
  public postWithNoBody<T>(url: string) {
    return this.http.post<T>(
      url,
      {},
      {
        withCredentials: true,
        responseType: 'json',
        headers: this.jsonHeaders
      }
    );
  }

  /**
   * Performs a basic DELETE to an URL with parameters
   * @param url URL to call
   * @param parameters Parameters to include as part of the call
   * @returns Observable with the result of the API call of the DELETE
   */
  public delete<T>(url: string, parameters: { [key: string]: string | number }) {
    return this.http.delete<T>(url, {
      params: parameters,
      withCredentials: true,
      responseType: 'json',
      headers: this.jsonHeaders
    });
  }

  /**
   * Performs a basic DELETE to an URL with NO parameters
   * @param url URL to call
   * @returns Observable with the result of the API call of the DELETE
   */
  public deleteWithNoParameters<T>(url: string) {
    return this.http.delete<T>(url, {
      withCredentials: true,
      responseType: 'json',
      headers: this.jsonHeaders
    });
  }
}
