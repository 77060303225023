<div class="flex flex-column py-2">
  @for(layout of layouts(); track layout; let i = $index) {
  <div class="flex flex-row align-items-center gap-2 p-2" (onClick)="onLayoutSelect(layout)">
    @if(editIndex === i) {
    <input pInputText type="text" [(ngModel)]="newString" />
    <p-button icon="fa-duotone fa-solid fa-check" (click)="editString()" />
    <p-button icon="fa-duotone fa-solid fa-times" class="p-button-danger" (click)="cancelEdit()" />
    } @else {
    <p-button
      class="flex-1"
      styleClass="w-12 cursor-pointer"
      [outlined]="layout !== defaultLayout"
      [label]="layout"
      (click)="onLayoutSelect(layout)" />
    <!-- <p-button size="small" icon="fa-duotone fa-solid fa-pencil" (click)="startEdit(i)" /> -->
    <p-button icon="fa-duotone fa-solid fa-trash" size="small" (click)="deleteLayout(i)" />
    }
  </div>
  }
</div>
