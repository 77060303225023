import { Layout } from '@adaptabletools/adaptable-angular-aggrid';
import { LayoutGroupName } from '@enums/layout-group-name.enum';

export const PulseGridId = 'PULSE_V20240917';

export const allGridLayouts: Layout[] = [
  {
    Columns: [
      'status',
      'ndc',
      'gname',
      'gtin',
      'expiryDate',
      'returnBy',
      'daysUnused',
      'quantity',
      'manu',
      'dist',
      'lot',
      'division',
      'facility',
      'location',
      'cost',
      'extendedCost'
    ],
    Name: `${LayoutGroupName.GlobalTracking} - Default`
  },
  {
    Columns: [
      'status',
      'ndc',
      'gname',
      'expiryDate',
      'returnBy',
      'gtin',
      'daysUnused',
      'quantity',
      'manu',
      'dist',
      'lot',
      'division',
      'location',
      'cost',
      'extendedCost'
    ],
    Name: `${LayoutGroupName.FacilityTracking} - Default`
  },
  {
    Columns: [
      'status',
      'ndc',
      'gname',
      'expiryDate',
      'gtin',
      'quantity',
      'manu',
      'dist',
      'lot',
      'patientId',
      'dispenserId',
      'dispenseDate',
      'division',
      'facility',
      'location',
      'cost',
      'extendedCost'
    ],
    Name: `${LayoutGroupName.DispensedDrugs} - Default`
  },
  {
    Columns: [
      'status',
      'ndc',
      'gname',
      'gtin',
      'quantity',
      'manufacturer',
      'distributor',
      'lotNumber',
      'division',
      'facility',
      'destroyedDate',
      'cost',
      'extendedCost'
    ],
    Name: `${LayoutGroupName.DestroyedDrugs} - Default`
  }
];
