import { inject, Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { HttpHelperService } from '@services/http-helper/http-helper.service';

import { DestroyedDrugRecord } from './models/DestroyedDrugRecord';
import { GlobalRecord } from './models/GlobalRecord';
import { DispensedDrugRecord } from './models/DispensedDrugRecord';
import { TenantHelperService } from '@services/tenant-helper/tenant-helper.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class PharmacyDemoService {
  private apiUrl = `${environment.apiUrl}v1/DemoPharmacy/`;

  private httpHelpers = inject(HttpHelperService);

  private tenantHelpers = inject(TenantHelperService);

  private tenantId = '';

  constructor() {
    this.tenantHelpers.tenant.pipe(takeUntilDestroyed()).subscribe({
      next: (tenant) => {
        this.tenantId = tenant.tenant;
      }
    });
  }

  public GlobalRecords() {
    return this.httpHelpers.get<GlobalRecord[]>(`${this.apiUrl}Global/${this.tenantId}`);
  }

  public DispensedRecords() {
    return this.httpHelpers.get<DispensedDrugRecord[]>(`${this.apiUrl}Dispensed/${this.tenantId}`);
  }

  public DestroyedRecords() {
    return this.httpHelpers.get<DestroyedDrugRecord[]>(`${this.apiUrl}Destroyed/${this.tenantId}`);
  }
}
